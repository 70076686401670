import { render, staticRenderFns } from "./ActivityLogTab.vue?vue&type=template&id=97e52ade&scoped=true&"
import script from "./ActivityLogTab.vue?vue&type=script&lang=js&"
export * from "./ActivityLogTab.vue?vue&type=script&lang=js&"
import style0 from "./ActivityLogTab.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ActivityLogTab.vue?vue&type=style&index=1&id=97e52ade&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e52ade",
  null
  
)

export default component.exports