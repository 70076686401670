<template>
  <div>
    <h2
      v-if="quote.id"
      class="notprintable"
      style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important"
    >
      {{ quote.opportunity.address + ", " + quote.opportunity.geography }}
    </h2>
    <uil-suitcase class="logo icon_possition" size="15px" />
    {{ quote.opportunity.account_name }}
    <span style="padding-left: 20px">
      <uil-tape class="logo icon_possition" size="15px" />
      {{
        quote.opportunity.rentable_sqft.toLocaleString("en", { useGrouping: true }) +
        " sqft"
      }}
    </span>
    <span style="padding-left: 20px">
      <uil-bill class="logo icon_possition" size="15px" />
      {{ quote.tfr.tfr }}
    </span>

    <span style="padding-left: 20px; font-weight: 400; color: #b9b9c3">
      {{ "DC Version " + parseFloat(quote.base_spec_version) }}
    </span>
    <p class="pt-1">
      <!-- pending to be validated -->
      <b-badge
        pill
        variant="primary"
        class="submitted-for-handover"
        v-if="quote.marked_as_active && $route.name == 'Quote Review'"
        >Active</b-badge
      >
      <b-badge
        v-if="!quote.submitted_for_handover == true"
        pill
        :class="badgeClass(quote.tfr.tfr_status)"
      >
        {{ quote.tfr.tfr_status }}
      </b-badge>
      <b-badge v-else pill variant="primary" class="submitted-for-handover"
        >Submitted for Handover</b-badge
      >
      <b-badge
        v-if="quote.submitted_for_handover == true&&quote.tfr.tfr_status=='Archived'"
        pill
        :class="badgeClass(quote.tfr.tfr_status)"
      >
        {{ quote.tfr.tfr_status }}
      </b-badge>
    </p>
  </div>
</template>

<script>
import { UilBill, UilSuitcase, UilTape } from "@iconscout/vue-unicons";
import { BBadge } from "bootstrap-vue";
export default {
  props: {
    quote: {
      type: Object,
    },
  },
  components: {
    UilBill,
    UilTape,
    UilSuitcase,
    BBadge,
  },
  methods: {
    badgeClass(status) {
      if (status == "Awaiting Test Fit") {
        return "awaiting-test-fit";
      } else if (status == "Reviewing Test Fit") {
        return "reviewing-test-fit";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Awaiting Quote") {
        return "awaiting-quote";
      } else if (status == "Quote in Progress") {
        return "quote-in-progress";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Reviewing Quote") {
        return "reviewing-quote";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      } else if (status == "Hardened") {
        return "hardened";
      } else if (status == "Quote Completed") {
        return "quote-completed";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon_possition {
  position: relative;
  top: 2px;
}
</style>
