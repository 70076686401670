<template>
<div style="width: inherit;">
    <label for="InputHelp" class="comment-label">Comments</label>
    <b-card style="height: auto;">
        <div v-if="comments.length != 0">
            <vue-perfect-scrollbar class="scrollable scroll-area" :settings="settings" id="comment-section">

                <b-row v-for="(comment,index) in comments" :key="index">

                    <b-col style="flex-grow: unset;" sm>
                        <b-avatar v-if="((initials(comment.user) == 'PL') || (initials(comment.user) == 'AL') || (initials(comment.user) == 'SR') || (initials(comment.user) == 'TR') )" size="40" variant="light-primary" badge :src="require('@/assets/images/avatars/gandalf.jpeg')" class="badge-minimal" badge-variant="success" />
                        <b-avatar v-else-if="((initials(comment.user) == 'EG') )" size="40" variant="light-primary" badge :src="require('@/assets/images/avatars/grumpy.jpg')" class="badge-minimal" badge-variant="success" />
                        <b-avatar v-else size="40" :variant="comment.user == userName ? 'light-primary' : 'light-secondary'" :text="initials(comment.user)" class="badge-minimal" />
                    </b-col>
                    <b-col sm>
                        <b-row>
                            <span class="user-name font-weight-bolder mb-0">
                                {{ comment.user }}
                            </span>
                        </b-row>
                        <b-row>
                            <small style="color: #B9B9C3;font-size: 12px;line-height: 18px;">{{ relativeTime(comment.created_at) }}</small>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="pt-1">
                        <p style="white-space: pre-line;line-break: anywhere;"> {{ comment.comment }} <br>
                            <a v-if="(index == 0) && (demolitionImage != undefined && demolitionImage != &quot;&quot;)" target="_blank" :href="`${demolitionImage}`">View Demolition Image
                                <feather-icon icon="ExternalLinkIcon" /> </a></p>
                    </b-col>

                </b-row>
            </vue-perfect-scrollbar>
        </div>
        <div v-else>
            <p style="    text-align: center;    font-style: normal;font-weight: normal;font-size: 18px;line-height: 21px;color: #B9B9C3;">
                No notes to display😵
            </p>
        </div>
        <hr class="dotted">
        <b-form-group>
            <label for="comments">Comment</label>
            <b-form-textarea id="comments" v-model="newComment" placeholder="Add a comment" rows="3" :state="newComment.length <= maxChar" :class="newComment.length > maxChar ? 'text-danger' : ''" @input="commentChanged()" /> 
            <small class="textarea-counter-value float-right" :class="newComment.length > maxChar ? 'bg-danger' : ''">
                <span class="char-count">{{ newComment.length }}</span> / {{ maxChar }}
            </small>
        </b-form-group>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-75" block :disabled="newComment == '' || newComment.length > maxChar" @click="formSubmit">
            Post Comment
        </b-button>
    </b-card>
</div>
</template>

<script>
import {
    UilConfused,
} from '@iconscout/vue-unicons'
import {
    FormWizard,
    TabContent,
} from 'vue-form-wizard'
import vSelect from 'vue-select'
import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTable,
    BOverlay,
    BFormTextarea,
    BCard,
    BButton,
    BAvatar,
    BContainer,
} from 'bootstrap-vue'
import {
    required,
    email,
} from '@validations'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    directives: {
        Ripple,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormFile,
        BCardText,
        BTable,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        vSelect,
        BFormInvalidFeedback,
        LottieAnimation,
        BOverlay,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        BFormTextarea,
        BCard,
        BButton,
        BAvatar,
        BContainer,
        moment,
        VuePerfectScrollbar,
        UilConfused,
    },
    props: ['comments', 'quoteId', 'demolitionImage'],
    data() {
        return {
            newComment: '',
            userName: localStorage.getItem('name'),
            show: false,
            glazing: '',
            demising: '',
            drywall: '',
            sqft: '',
            file: null,
            required,
            parsedCSV: [],
            location: '',
            opportunityID: '',
            spaces: [],
            doors: [],
            rooms: [],
            qs: [],
            plannerNotes: '',
            tfrLink: '',
            demolitionReferenceImage: '',
            takeoffs: [],
            userName: localStorage.getItem('name'),
            settings: {
                wheelPropagation: false,
                suppressScrollX: true,
            },
            maxChar: 1000,
        }
    },
    beforeUpdate() {},
    mounted() {
        if(this.comments.length > 0){
            document.getElementById('comment-section').scrollTop = document.getElementById('comment-section').scrollHeight
        }
    },
    updated(){  
        document.getElementById('comment-section').scrollTop = document.getElementById('comment-section').scrollHeight
    },
    methods: {
        commentChanged() {
            this.$emit('commentChanged', this.newComment)
        },
        formSubmit() {
            this.$http.post('/comments', {
                    comment: this.newComment,
                    quoteId: this.quoteId,
                    userName: this.userName,
                })
                .then(response => {
                    this.comments = response.data
                    this.newComment = ''
                })
                .catch(error => {
                    this.showToast('danger', error.response.data.message)
                })
        },
        relativeTime(date) {
            return this.$dateF.formatDate(date);
        },
        initials(text) {
            const initial = text.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('')
                .toUpperCase()

            return initial
        },

    },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style><style scoped>
.scrollable {
    max-height: 300px;
}
</style>
