<template>
  <b-modal
    id="edit-site-conditions-modal"
    size="lg"
    title="Edit Site Condition"
    cancel-title="Cancel"
    ok-title="Add Items"
    :no-close-on-backdrop="true"
    @ok="handleOk"
    @show="handleShow"
    @hidden="resetModal"
  >
    <b-overlay :show="show" large rounded="lg" spinner-variant="primary">
      <validation-observer ref="accountRules" tag="form">
        <b-row>
          <b-col md="12">
            <validation-provider #default="{ errors }" name="item" rules="required">
              <b-form-group>
                <label for="InputHelp">Item</label>
                <b-input-group>
                  <b-form-input
                    v-model="item"
                    type="text"
                    placeholder="Ex. Access Control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="InputHelp">Details</label>
              <small class="text-muted"> optional</small>
              <b-form-textarea
                id="details"
                v-model="details"
                placeholder="Ex. HID-compatible system, 2x suite entry doors"
                rows="2"
                :state="details.length <= maxChar"
                :class="details.length > maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-right"
                :class="details.length > maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ details.length }}</span> / {{ maxChar }}
              </small>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="InputHelp">Internal Notes</label>
              <small class="text-muted"> optional</small>
              <b-form-textarea
                id="details"
                v-model="internal_notes"
                placeholder="Ex. HID-compatible system, 2x suite entry doors"
                rows="2"
                :state="internal_notes.length <= maxCharInternal"
                :class="internal_notes.length > maxCharInternal ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-right"
                :class="internal_notes.length > maxCharInternal ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ internal_notes.length }}</span> /
                {{ maxCharInternal }}
              </small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Tender Code"
              :rules="{ required: true }"
            >
              <b-form-group>
                <label for="InputHelp">Tender Code</label>
                <v-select
                  v-model="tenderCode"
                  label="code_name"
                  :options="uniformat.Construction"
                  item-text="code_name"
                  required
                  :reduce="(tenderCode) => tenderCode.code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col md="6">
                    <validation-provider #default="{ errors }" name="price" rules="required">
                        <b-form-group>
                            <label for="InputHelp">Total Price (w/ D&I)</label>
                            <b-input-group prepend="$">
                                <b-form-input v-model="price" type="number" placeholder="$0.00" />
                            </b-input-group>
                            <small class="labelpersqft">Price/sqft: {{ "$" + parseFloat(price/sqft).toFixed(2) }} </small><br>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>
                </b-col> -->
          <b-col md="6">
            <validation-provider #default="{ errors }" name="cost" rules="required">
              <b-form-group>
                <label for="InputHelp">Total COGS</label>
                <b-input-group prepend="$">
                  <b-form-input
                    v-model="cost"
                    type="number"
                    placeholder="$0.00"
                    @keyup="constructionPrice()"
                  />
                </b-input-group>
                <small class="labelpersqft"
                  >COGS/sqft: {{ cost / sqft < 0 ? " -" : "" }}
                  {{ "$" + Math.abs(parseFloat(cost / sqft).toFixed(2)) }}</small
                ><br />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-checkbox
              id="checkbox-1"
              v-model="isBaseSpec"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              Hide Item in Turnkey
            </b-form-checkbox>
            <!-- <b-form-group>
                        <label for="InputHelp">Subtotal</label>
                        <h5> {{ "$" + Number(this.fields.price*this.sqft).toLocaleString(undefined, {minimumFractionDigits: 2})}}</h5>
                    </b-form-group> -->
          </b-col>
        </b-row>
      </validation-observer>
      <template #overlay>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
    </b-overlay>
    <template #modal-footer="{ ok, cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-col style="padding-left: 0px">
        <b-button variant="danger" @click="deleteSiteCondition()">
          <feather-icon icon="Trash2Icon" />
          Delete
        </b-button>
      </b-col>
      <b-col style="text-align: end; padding-right: 0px">
        <!-- Button with custom close trigger value -->
        <b-button
          variant="outline-secondary"
          style="margin-right: 10px"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button variant="primary" @click="ok()"> Save Option </b-button>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import uniformat from "@/assets/objects/uniformat-codes.json";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTable,
  BOverlay,
  BButton,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { required } from "@validations";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import tenderCodes from "@/assets/objects/tender-codes.json";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BSpinner,
    vSelect,
    BFormInvalidFeedback,
    LottieAnimation,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    BFormCheckbox,
  },
  props: ["quoteId", "sqft", "siteConditionToEdit"],
  data() {
    return {
      userName: localStorage.getItem("name"),
      show: false,
      isBaseSpec: false,
      item: "",
      details: "",
      internal_notes: "",
      price: 0,
      id: 0,
      cost: 0,
      fields: {
        details: "",
      },
      budget_group: "Construction",
      tenderCode: "",
      required,
      option: ["FF&E", "AV/IT", "Construction"],
      maxChar: 500,
      maxCharInternal:500,
      tenderCodes: tenderCodes.v1,
      uniformat: uniformat,
    };
  },

  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.validationForm();
    },
    handleShow() {
      this.item = this.siteConditionToEdit.name;
      this.details = this.siteConditionToEdit.details
        ? this.siteConditionToEdit.details
        : "";
      this.internal_notes = this.siteConditionToEdit.internal_note
        ? this.siteConditionToEdit.internal_note
        : "";
      this.price = this.siteConditionToEdit.total_price;
      this.cost = this.siteConditionToEdit.total_cost;
      this.isBaseSpec = this.siteConditionToEdit.is_base_spec;
      this.id = this.siteConditionToEdit.id;
      this.tenderCode = this.siteConditionToEdit.uniformat;

      // this.fields = this.siteConditionToEdit;
    },
    constructionPrice() {
      this.price = this.cost;
    },
    resetModal() {
      this.item = "";
      this.details = "";
      this.internal_notes = "";
      this.price = 0;
      this.cost = 0;
      this.budget_group = "";
      this.tenderCode = "";
      this.isBaseSpec = false;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
            this.show = true;
            this.fields = {
              details: this.details,
              internal_note: this.internal_notes,
              name: this.item,
              sqft: this.sqft,
              quoteId: this.quoteId,
              total_price: this.price,
              total_cost: this.cost,
              cost_per_sqft: parseFloat(this.cost / this.sqft).toFixed(2),
              price_per_sqft: parseFloat(this.price / this.sqft).toFixed(2),
              budget_group: "Construction",
              is_base_spec: this.isBaseSpec,
              // tender_code: this.tenderCode,
              uniformat: this.tenderCode,
            };
            // this.fields.price_per_sqft = parseFloat(this.fields.total_price/this.sqft).toFixed(2);
            // this.fields.cost_per_sqft = parseFloat(this.fields.total_cost/this.sqft).toFixed(2);
            this.$http
              .put(`/quote/${this.quoteId}/site-conditions/${this.id}`, this.fields)
              .then((response) => {
                this.$emit("updatedSiteConditions", response.data);

                this.show = false;
                this.$bvModal.hide("edit-site-conditions-modal");
              })
              .catch((error) => {
                this.show = false;
                this.showToast("danger", error.response.data.message);
              });
          } else {
            reject();
          }
        });
      });
    },
    deleteSiteCondition() {
      this.show = true;
      this.$http
        .delete(`/quote/${this.quoteId}/site-conditions/${this.id}`)
        .then((response) => {
          this.show = false;
          this.$emit("updatedSiteConditions", response.data);
          this.showToast(
            "Success",
            "Site Condition Deleted Successfully",
            "Notification"
          );
          this.$bvModal.hide("edit-site-conditions-modal");
        })
        .catch((error) => {
          this.show = false;
          this.showToast("danger", "Error Deleting Site Condition", "Notification");
          this.$bvModal.hide("edit-site-conditions-modal");
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
