<template>
<svg
    v-bind="$attrs"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    class="ui-svg-inline"
    viewBox="0 0 24 24"
    fill="currentColor"
>
    <path
    fill-rule="evenodd" clip-rule="evenodd"
     d="M5 5C5 3.34315 6.34315 2 8 2H16C17.6569 2 19 3.34315 19 5V8.17071C20.1652 8.58254 21 9.69378 21 11V17C21 18.6569 19.6569 20 18 20V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V20H8V21C8 21.5523 7.55228 22 7 22C6.44772 22 6 21.5523 6 21V20C4.34315 20 3 18.6569 3 17V11C3 9.69378 3.83481 8.58254 5 8.17071V5ZM17 5V8.17071C15.8348 8.58254 15 9.69378 15 11H9C9 9.69378 8.16519 8.58254 7 8.17071V5C7 4.44772 7.44772 4 8 4H16C16.5523 4 17 4.44772 17 5ZM6 10C5.44772 10 5 10.4477 5 11V17C5 17.5523 5.44772 18 6 18H18C18.5523 18 19 17.5523 19 17V11C19 10.4477 18.5523 10 18 10C17.4477 10 17 10.4477 17 11V13V14.5C17 15.6046 16.1046 16.5 15 16.5H9C7.89543 16.5 7 15.6046 7 14.5V11C7 10.4477 6.55228 10 6 10ZM9 13V14.5H15V13H9Z"
    
    />
</svg>
</template>
  
  <script>
 
  export default {
    props: {
      size: {
        type: String,
        default: '1em'
      }
    }
  }
  </script>

<style>
.ui-svg-inline {
  display: inline-block;
  font-size: inherit;
  overflow: visible;
  vertical-align: -0.125em;
}

</style>