var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:'swap_spec_modal_' + _vm.specType,attrs:{"id":'swap_spec_modal_' + _vm.specType,"title":"Swap Specification","ok-title":"Archive","cancel-title":"Go Back","cancel-variant":"outline-secondary","centered":true,"body-class":"modal-padding","no-close-on-backdrop":true},on:{"ok":_vm.saveSwapSpec,"close":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validateSave()}}},[_vm._v(" Save ")]),_c('b-button',{staticClass:"float-right ml-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.resetSpec}},[_vm._v(" Reset to Default Spec ")]),_c('b-button',{staticClass:"float-right ml-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"specSwapRules"},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Specification Name","label-for":"name-input","invalid-feedback":"Name is required"}},[_c('b-form-input',{attrs:{"id":"name-input","placeholder":"Specification Name","required":"","value":_vm.setForm},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('label',{attrs:{"for":"note"}},[_vm._v("Internal Note")]),_vm._v("   "),_c('label',{staticClass:"small text-muted",attrs:{"for":"optional"}},[_vm._v("Optional")]),_c('b-form-textarea',{class:_vm.internalNoteLength > _vm.maxChar ? 'text-danger' : '',attrs:{"id":"name-input","required":"","placeholder":"Internal notes and reason for changes.","state":_vm.internalNoteLength <= _vm.maxChar},model:{value:(_vm.form.internal_note),callback:function ($$v) {_vm.$set(_vm.form, "internal_note", $$v)},expression:"form.internal_note"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.form.internal_note > _vm.maxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.internalNoteLength))]),_vm._v(" / "+_vm._s(_vm.maxChar)+" ")]),_c('label',{staticClass:"mt-1",attrs:{"for":"note"}},[_vm._v("Manufacturer")]),_c('validation-provider',{attrs:{"name":"manufacturer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:_vm.manufacturerLength > _vm.maxCharManu ? 'text-danger' : '',attrs:{"id":"name-input","required":"","state":_vm.manufacturerLength <= _vm.maxCharManu},model:{value:(_vm.form.manufacturer),callback:function ($$v) {_vm.$set(_vm.form, "manufacturer", $$v)},expression:"form.manufacturer"}}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.form.manufacturer.length > _vm.maxCharManu ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.manufacturerLength))]),_vm._v(" / "+_vm._s(_vm.maxCharManu)+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"cogs"}},[_vm._v("COGS / Unit")]),_c('validation-provider',{attrs:{"name":"COGS / Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"placeholder":"0","type":"number"},model:{value:(_vm.form.unit_cogs),callback:function ($$v) {_vm.$set(_vm.form, "unit_cogs", $$v)},expression:"form.unit_cogs"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"mt-1"},[_c('uil-times',{staticStyle:{"width":"20px","height":"20px"}})],1),_c('div',{staticClass:"ml-2 text-right"},[_c('p',{staticClass:"pb-0 mb-0",staticStyle:{"font-size":"12px: font-weight:400"}},[_vm._v(" QTY ")]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v(_vm._s(_vm.qty))])])])]),_c('b-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"mt-1"},[_c('uil-grip-horizontal-line',{staticStyle:{"width":"20px","height":"20px"}})],1),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"pb-0 mb-0",staticStyle:{"font-size":"12px: font-weight:400"}},[_vm._v(" Total Price ")]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v(" "+_vm._s(Number(_vm.qty * _vm.form.unit_cogs).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])])])]),_c('b-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"1"}},[_c('div',{staticClass:"mt-2"},[_c('uil-times',{staticStyle:{"width":"20px","height":"20px"}})],1)]),_c('b-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"5"}},[_c('label',{staticClass:"m-0 p-0",attrs:{"for":"cogs"}},[_vm._v("Delivery & Installation")]),_c('validation-provider',{attrs:{"name":"Delivery & Installation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"placeholder":"22.5","type":"number"},model:{value:(_vm.form.unit_delivery_install),callback:function ($$v) {_vm.$set(_vm.form, "unit_delivery_install", $$v)},expression:"form.unit_delivery_install"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"mt-1"},[_c('uil-grip-horizontal-line',{staticStyle:{"width":"20px","height":"20px"}})],1),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"pb-0 mb-0",staticStyle:{"font-size":"12px: font-weight:400"}},[_vm._v(" Total Delivery & Installation ")]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v(" "+_vm._s(Number( _vm.qty * _vm.form.unit_cogs * (_vm.form.unit_delivery_install / 100) ).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])])])])],1),(_vm.base_spec_version>4.2)?_c('b-row',{staticClass:"mt-0"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{staticClass:"ml-1",attrs:{"for":"cogs"}},[_vm._v("Other Profit / Unit")]),_c('validation-provider',{attrs:{"name":"Other Profit / Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"ml-1",attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"placeholder":"0","type":"number"},model:{value:(_vm.form.rebate_unit),callback:function ($$v) {_vm.$set(_vm.form, "rebate_unit", $$v)},expression:"form.rebate_unit"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1436824659)})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"mt-1"},[_c('uil-times',{staticStyle:{"width":"20px","height":"20px"}})],1),_c('div',{staticClass:"ml-2 text-right"},[_c('p',{staticClass:"pb-0 mb-0",staticStyle:{"font-size":"12px: font-weight:400"}},[_vm._v(" QTY ")]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v(_vm._s(_vm.qty))])])])]),_c('b-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"mt-1"},[_c('uil-grip-horizontal-line',{staticStyle:{"width":"20px","height":"20px"}})],1),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"pb-0 mb-0",staticStyle:{"font-size":"12px: font-weight:400"}},[_vm._v(" Total Price ")]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v(" "+_vm._s(Number(1*_vm.qty * _vm.form.rebate_unit*1).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])])])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }