<template>
  <b-card class="invoice-preview-card" style="margin: auto; width: 800px">
    <!-- <div class="d-flex justify-content-between align-items-center;"  v-if="quote.geography == 'Montréal' ">
        <b-col cols="12">
            <h3 class="font-weight-bold">Quote Display</h3>
                    <hr >
        </b-col>
    </div>
    <div class="d-flex justify-content-between align-items-center cell-styling-double" style="height:65px;" v-if="quote.geography == 'Montréal' ">
        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column label-exclusions" cols="9">
            <div><p style="font-weight: 500;font-size: 15px;line-height: 24px;color:#6E6B7B;    margin-bottom: 0px;">Language</p>
            <small>Changes the language of the quote PDF package.</small></div>
        </b-col>
        <b-col align-self="end" class="align-items-center justify-content-start label-exclusions" cols="3">
            <v-select :clearable="false"  v-model="quote.language" :options="languageItems" item-value="value" label="label" item-text="label" :reduce="item => item.value" @input="toggleChanged()"></v-select>
        </b-col>
    </div>
    <b-col cols="12" v-if="quote.geography == 'Montréal' " style="padding-bottom: 60px;">
    <hr>
    </b-col> -->
    <div
      v-if="Number(quote.base_spec_version) >= 3"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col cols="12">
        <h3 class="font-weight-bold">Estimate Display</h3>
        <hr />
      </b-col>
    </div>
    <div
      v-if="Number(quote.base_spec_version) >= 3"
      class="d-flex justify-content-between align-items-center my-1"
    >
      <b-col align-self="start" cols="11">
        <h5>Show Tender Breakdown</h5>
        <small
          >Toggles the display of the construction tender breakdown in the
          estimate.</small
        >
      </b-col>
      <b-col
        align-self="end"
        class="d-flex align-items-center justify-content-end"
        cols="1"
      >
        <b-form-checkbox
          id="tender"
          v-model="quote.tender_breakdown_hidden"
          :checked="true"
          switch
          @change="toggleChanged()"
        />
      </b-col>
    </div>
    <b-col cols="12" v-if="Number(quote.base_spec_version) >= 4.2">
      <hr></b-col>
    <div
        v-if="Number(quote.base_spec_version) >= 4.2"
        class="d-flex justify-content-between align-items-center my-1"
    >

      <b-col align-self="start" cols="9">
        <h5>Update project duration (Weeks)</h5>
        <small
        >This update modifies the values for site supervision and parking in the quote, applying the changes once the quote is saved.</small
        >
      </b-col>
      <b-col
          align-self="end"
          class="d-flex align-items-center justify-content-end"
          cols="3"
      >
        <select v-model="quote.weeks" name="weeks" class="form-control mb-2">
          <option v-for="week in 30" :key="week" :value="week">{{ week}} Week{{week>1?'s':''}}</option>
        </select>
      </b-col>
    </div>
    <section v-if="Number(quote.base_spec_version) > 3.6">
      <div class="d-flex justify-content-between align-items-center">
        <b-col cols="12">
          <h3 class="font-weight-bold">Construction Management Fee</h3>
          <hr />
        </b-col>
      </div>

      <div class="d-flex justify-content-between align-items-center my-1">
        <b-col align-self="start" cols="11">
          <div class="d-flex">
            <b-input-group style="width: 120px">
              <b-input-group-prepend is-text>
                <feather-icon icon="PercentIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="ConstructionContingencyFee"
                required
                placeholder="5"
                v-model="quote.cm_fee_percentage"
                type="number"
                step="0.01"
                :disabled="
                  quote.tfr.tfr_status == 'Quote Completed' ||
                  quote.opportunity.ccdc_signed == true||quote.cm_fee_enabled==0
                "
              ></b-form-input>
            </b-input-group>
          </div>
        </b-col>
        <b-col
          align-self="end"
          class="d-flex align-items-center justify-content-end"
          cols="1"
        >
          <b-form-checkbox
            id="ConstructionContingencyFeeToggle"
            v-model="quote.cm_fee_enabled"
            :checked="true"
            switch
            :disabled="
              quote.tfr.tfr_status == 'Quote Completed' ||
              quote.opportunity.ccdc_signed == true
            "
            @change="toggleChanged()"
          />
        </b-col>
      </div>
    </section>
    <div class="d-flex justify-content-between align-items-center">
      <b-col cols="12">
        <hr v-if="Number(quote.base_spec_version) >= 3" />
        <h3
          :style="
            quote.tfr.tfr_status == 'Quote Completed' ||
            quote.opportunity.ccdc_signed == true
              ? 'opacity:0.5'
              : ''
          "
          class="font-weight-bold mt-4"
        >
          Scope of Work
        </h3>
        <hr />
      </b-col>
    </div>
    <div
      class="d-flex justify-content-between align-items-center my-1"
      :style="
        quote.tfr.tfr_status == 'Quote Completed' || quote.opportunity.ccdc_signed == true
          ? 'opacity:0.5'
          : ''
      "
    >
      <b-col align-self="start" cols="11">
        <h5>FF&E</h5>
        <small>Turning this off will completely remove the base spec FF&E budget.</small>
      </b-col>
      <b-col
        align-self="end"
        class="d-flex align-items-center justify-content-end"
        cols="1"
      >
        <b-form-checkbox
          id="ffe"
          v-model="quote.ffe_toggle"
          :checked="true"
          switch
          :disabled="
            quote.tfr.tfr_status == 'Quote Completed' ||
            quote.opportunity.ccdc_signed == true
          "
          @change="toggleChanged()"
        />
      </b-col>
    </div>
    <hr v-if="Number(quote.base_spec_version) < 3.3" />
    <div
      v-if="Number(quote.base_spec_version) < 3.3"
      class="d-flex justify-content-between align-items-center my-1"
      :style="
        quote.tfr.tfr_status == 'Quote Completed' || quote.opportunity.ccdc_signed == true
          ? 'opacity:0.5'
          : ''
      "
    >
      <b-col align-self="start" cols="11">
        <h5>AV / IT</h5>
        <small
          >Turning this off will completely remove the base spec AV / IT budget.</small
        >
      </b-col>
      <b-col
        align-self="end"
        class="d-flex align-items-center justify-content-end"
        cols="1"
      >
        <b-form-checkbox
          id="avit"
          v-model="quote.avit_toggle"
          :checked="true"
          switch
          :disabled="
            quote.tfr.tfr_status == 'Quote Completed' ||
            quote.opportunity.ccdc_signed == true
          "
          @change="toggleChanged()"
        />
      </b-col>
    </div>
    <hr v-if="Number(quote.base_spec_version) >= 3.3" />
    <div
      v-if="Number(quote.base_spec_version) >= 3.3"
      class="d-flex justify-content-between align-items-center my-1"
      :style="
        quote.tfr.tfr_status == 'Quote Completed' || quote.opportunity.ccdc_signed == true
          ? 'opacity:0.5'
          : ''
      "
    >
      <b-col align-self="start" cols="11">
        <h5>Audio Visual</h5>
        <small>Turning this off will completely remove the base spec AV budget.</small>
      </b-col>
      <b-col
        align-self="end"
        class="d-flex align-items-center justify-content-end"
        cols="1"
      >
        <b-form-checkbox
          id="av"
          v-model="quote.av_toggle"
          :checked="true"
          switch
          :disabled="
            quote.tfr.tfr_status == 'Quote Completed' ||
            quote.opportunity.ccdc_signed == true
          "
          @change="toggleChanged()"
        />
      </b-col>
    </div>
    <hr v-if="Number(quote.base_spec_version) >= 3.3" />
    <div
      v-if="Number(quote.base_spec_version) >= 3.3"
      class="d-flex justify-content-between align-items-center my-1"
      :style="
        quote.tfr.tfr_status == 'Quote Completed' || quote.opportunity.ccdc_signed == true
          ? 'opacity:0.5'
          : ''
      "
    >
      <b-col align-self="start" cols="11">
        <h5>Networking</h5>
        <small>Turning this off will completely remove the base spec IT budget.</small>
      </b-col>
      <b-col
        align-self="end"
        class="d-flex align-items-center justify-content-end"
        cols="1"
      >
        <b-form-checkbox
          id="it"
          v-model="quote.it_toggle"
          :checked="true"
          switch
          :disabled="
            quote.tfr.tfr_status == 'Quote Completed' ||
            quote.opportunity.ccdc_signed == true
          "
          @change="toggleChanged()"
        />
      </b-col>
    </div>
    <hr />
    <div v-if="Number(quote.base_spec_version) < 3">
      <div class="pb-4" style="padding-top: 60px">
        <b-col class="mb-2">
          <h3 class="font-weight-bold">Assumed Existing Site Conditions</h3>
        </b-col>
        <hr />
        <div class="d-flex justify-content-between align-items-center my-1">
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Max height (floor to underside of deck)</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            11.5 ft
          </b-col>
        </div>
        <hr />
        <div
          class="d-flex justify-content-between align-items-center my-1"
          v-if="quote.geography == 'Toronto'"
        >
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Building is free of Union labour associations</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox
              id="union_labour_free"
              v-model="quote.assumptions_exclusions.union_labour_free"
              :checked="true"
              switch
              @change="toggleChanged()"
            />
          </b-col>
        </div>
        <div class="d-flex justify-content-between align-items-center my-1" v-else>
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #b9b9c3"
              >Building is free of Union labour associations </span
            ><uil-info-circle id="unionLabour" />
            <b-tooltip target="unionLabour" triggers="hover">
              <span
                >This geography requires union labour. This line willl not be shown on the
                quote.</span
              >
            </b-tooltip>
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox id="union_labour_free" :checked="false" switch disabled />
          </b-col>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center my-1">
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Site is clear of Raised Access Flooring</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox
              id="raised_access_floor_clear"
              v-model="quote.assumptions_exclusions.raised_access_floor_clear"
              :checked="true"
              switch
              @change="toggleChanged()"
            />
          </b-col>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center my-1">
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Site clean and clear of furniture and fixtures</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox
              v-model="quote.assumptions_exclusions.ffe_clear"
              :checked="true"
              switch
              @change="toggleChanged()"
            />
          </b-col>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center my-1">
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Site condition meets building code</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox
              v-model="quote.assumptions_exclusions.meets_building_code"
              :checked="true"
              switch
              @change="toggleChanged()"
            />
          </b-col>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center my-1">
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Base Building equipment in good working condition</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox
              v-model="quote.assumptions_exclusions.building_equipment_good_condition"
              :checked="true"
              switch
              @change="toggleChanged()"
            />
          </b-col>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center my-1">
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Demising, interior partitions are built & finished slab-to-slab</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox
              v-model="quote.assumptions_exclusions.built_demising_interior_partitions"
              :checked="true"
              switch
              @change="toggleChanged()"
            />
          </b-col>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center my-1">
          <b-col align-self="start" cols="11">
            <span class="font-weight-normal" style="font-size: 14px; color: #6e6b7b"
              >Site is clear of hazardous material</span
            >
          </b-col>
          <b-col
            align-self="end"
            class="d-flex align-items-center justify-content-end"
            cols="1"
          >
            <b-form-checkbox
              v-model="quote.assumptions_exclusions.hazardous_material_clear"
              :checked="true"
              switch
              @change="toggleChanged()"
            />
          </b-col>
        </div>
        <hr />
      </div>
      <div class="">
        <b-col class="mb-2">
          <h3 class="font-weight-bold">Exclusions & Out of Scope</h3>
        </b-col>
        <div
          class="d-flex justify-content-between align-items-center first-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Demolition
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.demolition"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Access control, automatic door openers and/or maglock devices
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.access_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Additional BAS specific systems (e.g., Encelium, Johnson Controls, Carma
            meters)
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.bas_systems_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            New electrical panel and/or transformer
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.electrical_panel_transformer_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Additional demising walls
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.aditional_demising_walls_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Perimeter bulkhead on exterior partitions
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.perimeter_bulkhead_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Blinds or replacement of blinds
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.blinds_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Suite entry doors
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.suite_entry_doors_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Washroom work
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.washroom_work_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Elevator lobby work (defined as outside suite entry doors)
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.elevator_lobby_work_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            Abatement of any hazardous material on site
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.abatement_hazardous_material_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            <div>
              Landlord required reviews and backcharges<br />
              <small
                >(incl. after hour fees, commissioning, security, engineer reviews,
                etc.)</small
              >
            </div>
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.landlord_required_reviews_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-between align-items-center following-row-border cell-styling-double"
          style="height: 65px"
        >
          <b-col
            align-self="start"
            class="d-flex align-items-center cell-styling first-column label-exclusions"
            cols="9"
          >
            <div>Fireproofing (spray) on ceiling</div>
          </b-col>
          <b-col
            align-self="end"
            class="align-items-center justify-content-start label-exclusions"
            cols="3"
          >
            <v-select
              :clearable="false"
              v-model="quote.assumptions_exclusions.fireproofing_spray_included"
              :options="items"
              item-value="value"
              label="label"
              item-text="label"
              :reduce="(item) => item.value"
              @input="toggleChanged()"
            ></v-select>
          </b-col>
        </div>
      </div>
    </div>
  </b-card>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BTooltip,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { UilInfoCircle } from "@iconscout/vue-unicons";

export default {
  name: "ReviewConstructionSpecSection",
  data() {
    return {
      settings: {},
      items: [
        {
          label: "Not Included",
          value: false,
        },
        {
          label: "Included",
          value: true,
        },
      ],
      languageItems: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "Français",
          value: "fr",
        },
      ],
    };
  },
  components: {
    BCard,
    BCardBody,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    vSelect,
    UilInfoCircle,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
  },
  props: ["quote"],
  methods: {
    toggleChanged() {
      this.settings.assumptions_exclusions = this.quote.assumptions_exclusions;
      this.settings.ffe_toggle = this.quote.ffe_toggle;
      this.settings.avit_toggle = this.quote.avit_toggle;
      this.settings.av_toggle = this.quote.av_toggle;
      this.settings.it_toggle = this.quote.it_toggle;
      this.settings.language = this.quote.language;
      this.settings.tender_breakdown_hidden = this.quote.tender_breakdown_hidden;
      this.settings.cm_fee_enabled = this.quote.cm_fee_enabled;
      this.settings.cm_fee_percentage = parseFloat(this.quote.cm_fee_percentage);
      this.$emit("optionsChanged", this.settings);
    },
  },
};
</script>

<style scoped>
.label-exclusions {
  margin: auto;
}

.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}

.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}

.cell-styling {
  height: 33px;
}

.cell-styling-double {
  height: 65px;
}
</style>
